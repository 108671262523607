import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import arrow from "../../image/Rectangle 66.svg";
import mainIcon from "../../image/mission_icon.svg";
import dot from "../../image/4dot.png";

import "./Content.css";
import NewNavbar from "../NavBar/NewNavbar";
import { Helmet } from "react-helmet";

function Vision(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const canonicalUrl = `https://hermanngmeinerschoolfaridabad.org/vision-and-mission`;
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Helmet>
        <head>
          <link rel="canonical" href={canonicalUrl} />
        </head>
        {/* You can also add other meta tags here if needed */}
      </Helmet>
      <NewNavbar handleClick={handleClick} />
      {/* <Navbar isScrolled={isScrolled} handleClick={handleClick} /> */}
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div style={{ marginBottom: "-6rem" }}>
        <div
          style={{
            width: "204.11px",
            height: "204.64px",
            marginLeft: "74%",
            marginTop: "-7rem",
            background: "#F919848A",
            borderRadius: "0 0 100px 100px",
          }}
        ></div>
        <div
          style={{
            width: "204.11px",
            height: "204.64px",
            marginLeft: "83.5%",
            marginTop: "-11rem",
            background: "#1FDC007D",
            borderRadius: "0 0 100px 100px",
          }}
        ></div>
      </div>
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#23E303F5" }}>
            <img
              className="visionimage"
              src={mainIcon}
              style={{ marginRight: "1rem" }}
            />
            <Helmet>
              <meta name="title" content="Vision & Mission" />
              <title>Vision & Mission</title>
            </Helmet>
            <h1
              className={`f-2 f-500 mb-3`}
              style={{ color: "#23E303F5", display: "inline-block" }}
            >
              Vision & Mission
            </h1>
          </div>
          <div
            style={{
              marginTop: "2rem",
              backgroundColor: "#F919840A",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <img src={arrow} />
            <p
              style={{
                fontSize: "1.5rem",
                marginTop: "-3rem",
                marginLeft: "3.2rem",
              }}
            >
              <h2
                style={{
                  fontSize: "1.5rem",
                }}
              >
                The School
              </h2>
            </p>
            <div
              className="d-flex gap-2"
              style={{
                marginTop: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <div>
                <div className="color-grey f-500" style={{ margin: "1.5rem" }}>
                  Hermann Gmeiner School Faridabad, a pioneering institution in
                  the realm of education, established in 1986. With a student
                  body of 900, we maintain a personalized 30:1 student-teacher
                  ratio to ensure a focused and enriching learning experience.
                  Our commitment to providing a futuristic education is
                  reflected in the incorporation of cutting-edge facilities. The
                  school houses a state-of-the-art STEM (Science, Technology,
                  Engineering, and Mathematics) lab, where students are
                  encouraged to explore, innovate, and apply theoretical
                  knowledge to practical problem-solving. Additionally, our ATL
                  (Atal Tinkering Lab) serves as a hub for cultivating
                  creativity and honing the innovative spirit in our students.
                  Here, they engage in hands-on projects, experiments, and
                  develop critical thinking skills essential for the challenges
                  of the modern world. At Hermann Gmeiner School, financial
                  barriers should never hinder a child's access to quality
                  education. That's why we proudly offer tuition-free education,
                  making our institution accessible to all segments of society.
                  This commitment to inclusivity is a cornerstone of our
                  philosophy, ensuring that every student has the opportunity to
                  thrive academically. Our holistic curriculum goes beyond
                  traditional boundaries, fostering the all-round development of
                  our students. In addition to the STEM and ATL labs, the school
                  places a strong emphasis on co-curricular activities such as
                  music and dance, contributing to the artistic and cultural
                  growth of our students. Physical well-being is equally
                  prioritized, with a well-equipped gymnasium providing a space
                  for students to nurture their physical fitness. Hermann
                  Gmeiner School Faridabad has garnered numerous awards from
                  esteemed institutions, a testament to our unwavering
                  commitment to educational excellence. These accolades further
                  motivate us to continually innovate and provide a world-class
                  education to our students. For those who seek knowledge beyond
                  the confines of textbooks, our physical and e-library, housing
                  an extensive collection of over 5000 books, beckons students
                  to explore the vast realms of literature and information. Join
                  us at Hermann Gmeiner School Faridabad, where education is not
                  just a journey; it's a transformative experience. Be part of a
                  community that values curiosity, innovation, and the holistic
                  development of every student.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "2rem",
              backgroundColor: "#F919840A",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <img src={arrow} />
            <p
              style={{
                fontSize: "1.5rem",
                marginTop: "-3rem",
                marginLeft: "3.2rem",
              }}
            >
              <h2
                style={{
                  fontSize: "1.5rem",
                }}
              >
                Vision
              </h2>
            </p>
            <div
              className="d-flex gap-2"
              style={{
                marginTop: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <div>
                <div className="color-grey f-500" style={{ margin: "1.5rem" }}>
                  We at Hermann Gmeiner School believe in scholarly and
                  accomplished learning. To provide all our students with
                  advanced and qualified academic education along with the
                  knowledge and experience in sports and co-curricular
                  activities has always been our priority. Blending leisure with
                  technology and creating a holistic experience for the
                  educators to have the finest learning experience has been the
                  precedence of our institution. We tend to upskill and upgrade
                  all the children at the primary age with the will and spirit
                  to be the best and brightest version of themselves. This helps
                  them to grow and develop individually and in the society to
                  become a good and contributing citizen of our country.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "2rem",
              backgroundColor: "#1FDC000A",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <img src={arrow} />
            <p
              style={{
                fontSize: "1.5rem",
                marginTop: "-3rem",
                marginLeft: "3.2rem",
              }}
            >
              <h2
                style={{
                  fontSize: "1.5rem",
                }}
              >
                Mission
              </h2>
            </p>
            <div
              className="d-flex gap-2"
              style={{
                marginTop: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <div>
                <div className="color-grey f-500" style={{ margin: "1.5rem" }}>
                  The mission of Hermann Gmeiner School is to create a safe
                  space that nurtures the cerebral, social, emotional, physical,
                  spiritual and academic development of every child. Our school
                  strives to encourage and develop a sense of confidence and
                  self-assurance in every juvenile. With a wide yet safe
                  environment along with empathetic and patient staff, we want
                  to empower our students and help them rise at every step of
                  learning. By inculcating family and society values along with
                  practical and theoretical knowledge, the children would learn
                  the significance of selfhood and brotherhood.
                </div>
              </div>
            </div>
            <img src={dot} style={{ width: "5rem", marginLeft: "90%" }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Vision;
