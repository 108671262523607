import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import life_skill_1 from "../../image/life_skill_1.jpg";
import life from "../../image/newcovers/Life skill curriculum inside pic.jpg";
import SideBar from "../SideBar/SideBar";
import "./Content.css";
import { Col, Row } from "react-bootstrap";
import NewNavbar from "../NavBar/NewNavbar";
import { Helmet } from "react-helmet";
function Lifeskill(props) {
  const urlHere = `https://hermanngmeinerschoolfaridabad.org/life-skill`;
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };

  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  // changed to skill array , rather than brute forcing it
  const skills = [
    { id: 1, title: "Self-awareness", color: "#06C5AE" },
    { id: 2, title: "Problem Solving", color: "#FF8C22" },
    { id: 3, title: "Critical Thinking", color: "#4CAF50" },
    { id: 4, title: "Decision Making", color: "#FF5722" },
    { id: 5, title: "Creative Thinking", color: "#03A9F4" },
    { id: 6, title: "Interpersonal Skills", color: "#9C27B0" },
    { id: 7, title: "Empathy", color: "#FFC107" },
    { id: 8, title: "Communication Skills", color: "#E91E63" },
    { id: 9, title: "Coping with Stress", color: "#673AB7" },
    { id: 10, title: "Coping with Emotions", color: "#3F51B5" },
  ];

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Helmet>

          <link rel="canonical" href={urlHere} />

      </Helmet>
      <NewNavbar handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />

      <div className="contentmain_cont">
        <div className="circle-div">
          <div
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "0 0 100px 100px",
              marginLeft: "45%",
              marginTop: "-8rem",
              backgroundColor: "#06C5AED1",
            }}
          ></div>
          <div
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "0 0 100px 100px",
              marginLeft: "50.5%",
              marginTop: "-11rem",
              backgroundColor: "#FF8C22D1",
            }}
          ></div>
        </div>
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div
            className={`f-2 f-500 lifeskilldiv`}
            style={{ color: "#FF8C22E8" }}
          >
            <img
              src={life}
              style={{
                width: "2rem",
                marginRight: "1rem",
                color: "#FF8C22E8",
              }}
              alt="Life Skill Curriculum"
            />
            <div className="lifeskills">Life Skill Curriculum</div>
          </div>
          <div
            className={`life_skill`}
            style={{
              width: "100%",
              display: "flex",
              marginTop: "1.5rem",
              flexDirection: "column", // Stack content on mobile
              alignItems: "center", // Center content on mobile
            }}
          >
            <div
              className="lifeskill_container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                className="lifeskillimage"
                src={life_skill_1}
                style={{
                  width: "100%", // Adjust width to fit on mobile
                  maxWidth: "20rem", // Cap max width
                  border: "4px solid #FF8C22",
                  filter: "drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.25))",
                  marginBottom: "1rem", // Add margin below image on mobile
                }}
                alt="Life Skill"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <p className="color-grey f-500 my-4">
                  Adapting the Life Skill Curriculum in the daily routine of
                  every child at the primary stage is a very crucial part of our
                  education. The basic hygiene importance like handwashing and
                  daily bathing, and the value of chivalry and gratitude towards
                  one another must be taught at their impressionable age and
                  fortified over the years. The three components of the Life
                  Skill Curriculum; the individual life-skill, the social skill,
                  and the effective decision-making skills are some of the
                  eminent topics of our syllabus. To not only make our students
                  academic geniuses, but to also refine their social and
                  personal skills is what we aim for.
                </p>
                <p className="color-grey f-500">
                  We believe in testing and creating challenges for our
                  students, so they can break their barriers and overcome their
                  judgemental and stereotypical fears. To educate all of them
                  about daily chores, irrespective of the gender, makes the
                  batchmates comfortable and more open to these topics. The ten
                  core Life Skills adopted by our education system are:-
                </p>
              </div>
            </div>
          </div>
          <Row className="skillrow">
            {skills.map((skill) => (
              <Col
                key={skill.id}
                className="skillcol"
                style={{
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  fontSize: "1.2rem",
                }}
              >
                <ol className="skillrow">
                  <div className="skillroww">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                        border: `5px solid ${skill.color}`,
                        padding: "0.2rem",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          border: "1px solid",
                        }}
                      >
                        {skill.id}.
                      </p>
                    </div>
                    <li
                      className="skillcoll"
                      style={{
                        backgroundColor: `${skill.color}0D`,
                        width: "100%",
                        marginTop: "0.7rem",
                        marginLeft: "25px",
                        paddingLeft: "0.3rem",
                        paddingBottom: "0.2rem",
                      }}
                    >
                      {skill.title}
                    </li>
                  </div>
                </ol>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Lifeskill;
